<template>
  <spinner-layout :loading="loading">
    <template v-if="productSelected">
      <div v-if="packName || items > 1" class="btn_back mu-mt-100 mu-mb-100">
        <m-link icon="ArrowArrowLeft48">
          <template v-slot:default>
            <router-link
              :to="{
                name: 'Products',
                params: {
                  group,
                  storeCode
                }
              }"
            >
              <div v-if="packName!=''">
                {{
                  $t('ui.button.btnBack', {packName} )
                }}
              </div>
              <div v-else>
                {{
                  $t('ui.button.btnBackGeneric')
                }}
              </div>
            </router-link>
          </template>
        </m-link>
      </div>

      <!-- SEARCH PRODUCT BY REFERENCE -->
      <div class="ml-flexy__col--full">
        <div class="ml-flexy">
          <div class="mc-left-icon-input">
            <svg class="mc-left-icon-input__icon">
              <use xlink:href="#iconS" />
            </svg>
            <input
              type="search"
              class="mc-left-icon-input__input mc-text-input mc-field__input"
              id="searchByReference"
              :placeholder="$t('ui.label.product.searchByReference')"
              name="searchByReference"
              v-model="searchedSku"
            />
          </div>
          <button type="button" class="mc-button" @click="searchProductByReference()">
              <span class="mc-button__label"> 
                {{ $t('ui.button.btnSearchByReference') }}
              </span>
          </button>
        </div>          
      </div>

      <svg xmlns="http://www.w3.org/2000/svg" style="display: none">
        <symbol id="iconS" viewBox="0 0 24 24">
          <path
            d="M18 6.05a7 7 0 00-10.51 9.14l-3.08 3.08a1 1 0 001.41 1.42l3.09-3.09A7 7 0 0018 6.05zm-1.41 8.49a5 5 0 110-7.08 5 5 0 01.04 7.08z"
          />
        </symbol>
      </svg>

      <!-- PRODUCT INFORMATION -->
      <div
        :class="[
          productSelected.originalPrice > productSelected.finalPrice ? 'border-promotion' : ''
        ]"
        class="container-detail mu-mb-100"
      >
        <div class="ml-container">
          <div class="ml-flexy">

            <!-- PRODUCT DETAIL -->
            <div class="ml-flexy__col--full">
              <div
                v-if="productSelected.catalogPrice > productSelected.finalPrice"
                class="promotion-pro-card"
              >
                <div class="promotion-pro-card__offer mu-mb-100">
                  <span class="mu-mr-050">
                    {{ $t('ui.label.product.offer') }}
                  </span>
                  <img src="@/assets/img/pro_logo_white_card.png" />
                </div>
              </div>

              <div class="container-detail__item">

                <!-- NAME -->
                <p class="name">
                  {{ productSelected.productName }}
                </p>

                <!-- REFERENCE -->
                <p class="reference mu-mt-025">
                  {{ `(Ref. ${productSelected.sku})` }}
                </p>

                <!-- LAST UPDATE DATE -->
                <p v-if="productSelected.lastUpdateDate" class="date mu-mt-025">
                  {{ stringToDate(productSelected.lastUpdateDate) }}
                </p>

              </div>
            </div>

            <!-- IMAGES CARROUSEL  -->
            <div class="ml-flexy__col--full mu-mt-100">
              <div class="container-detail__item">
                <carousel :per-page-custom="[[0, 1]]">
                  <slide
                    v-for="(image, index) in slides"
                    :key="index"
                    :data-index="index"
                    :data-name="`MySlideName-${index}`"
                  >
                    <img :src="image" />
                  </slide>
                </carousel>
              </div>
            </div>

            <div v-if="productSelected.tutorials.length > 0" class="ml-flexy__col--full mu-mt-100">
              <div class="container-detail__item">
                <m-link
                  v-for="(item, index) in productSelected.tutorials"
                  :id="`video-${index}`"
                  :key="index"
                  class="reference"
                  :href="item.url"
                  target="_blank"
                >
                  <template v-slot:default>
                    <m-button
                      class="mu-ml-050"
                      size="s"
                      :icon="item.icon"
                      :label="item.label"
                      theme="bordered-primary-02"
                    />
                  </template>
                </m-link>
              </div>
            </div>

            <!-- DESCRIPTION -->
            <div class="ml-flexy__col--full mu-mt-100">
              <div class="container-detail__item">
                <p class="description">
                  {{ productSelected.productDescription }}
                </p>
              </div>
            </div>

            <!-- PRICE -->
            <div class="ml-flexy__col--full">
              <div class="container-detail__item">
                <div
                  v-if="productSelected.originalPrice > productSelected.finalPrice"
                  class="promotion"
                >
                  <p class="promotion__price mu-mb-025">
                    {{ formatPromotionPrice(productSelected) }}
                  </p>
                  <p class="promotion__offer">
                    {{ $t('ui.label.product.offer') }}
                  </p>
                  <p class="promotion__total--price">
                    {{ formatFinalPrice(productSelected) }}
                  </p>
                </div>
                <div v-else class="promotion">
                  <!-- <p>{{ eurFormat(productSelected.finalPrice) }}</p> -->
                  <p class="promotion">
                    {{ formatFinalPrice(productSelected) }}
                  </p>
                </div>
              </div>
            </div>

            <!-- STOCK -->
            <div class="ml-flexy__col--full">
              <div class="container-detail__item">
                <div v-if="productSelected.maxStock > 0">
                  <p>
                    {{ productSelected.maxStock }} {{ $t('ui.label.product.inStock') }}
                    <span class="stock-status_badge stock-status_badge--green"></span>
                  </p>
                </div>
                <div v-if="productSelected.maxStock === 0">
                  <p>
                    {{ $t('ui.label.product.notAvailable') }}
                    <span class="stock-status_badge"></span>
                  </p>
                </div>
                <div v-if="productSelected.maxStock === -1">
                  <p>{{ $t('ui.label.product.notForSale') }}</p>
                </div>
                <div v-if="productSelected.maxStock === -2">
                  <p>{{ $t('ui.label.product.uponRequest') }}</p>
                </div>               
              </div>
            </div>

            <div
              v-if="especialUnityCapacityValues.includes(productSelected.unitCapacity)"
              class="ml-flexy__col--full mu-mt-100"
            >
              <div class="container-detail__item">
                <p class="reference" v-html="formatPriceByUnity(productSelected)" />
              </div>
            </div>

            <div v-if="packName || items > 1" class="ml-flexy__col--full mu-mt-200">
              <div class="container-detail__item">
                <router-link
                  :to="{
                    name: 'Products',
                    params: {
                      group,
                      storeCode
                    }
                  }"
                >
                  <m-button v-if="packName!=''"
                    icon="ArrowArrowLeft48"
                    :label="
                      $t('ui.button.btnBack', {
                        packName
                      })
                    "
                    theme="bordered-neutral"
                  />
                  <m-button v-else
                    icon="ArrowArrowLeft48"
                    :label="
                      $t('ui.button.btnBackGeneric')
                    "
                    theme="bordered-neutral"
                  />
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- SEARCH AVAILABILITY -->
      <div class="ml-container center">
        <button type="button" class="mc-button mc-button--bordered-neutral" @click="changeStore()">
          <span class="mc-button__label"> 
            {{ $t('ui.button.btnSearchAvailability') }}
          </span>
        </button>
      </div>
    </template>
  </spinner-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_STORES } from '@/store/actions/stores';
import { GET_PRODUCTS } from '@/store/actions/products';
import { eurFormat, currency } from '@/utils/numbers';

import store from '@/store';
import { SET_STORE_SELECTED } from '@/store/actions/stores';
import { lsRemoveSelectedStore, lsSetSelectedStore, lsStoreSelected, storageAvailable } from '@/utils/localStore';
import LoadingMixin from '@/mixins/LoadingMixin';
import { textByUnitCapacityFn, especialUnityCapacityValues } from '@/utils/helpers';
import { stringToDate } from '@/utils/date';

import { Carousel, Slide } from 'vue-carousel';

const getStores = storeCode =>
  new Promise((resolve, reject) => {
    store
      .dispatch(GET_STORES, storeCode)
      .then(stores => resolve(stores))
      .catch(err => reject(err.message));
  });

const getProducts = (sku, storeCode) =>
  new Promise((resolve, reject) => {
    store
      .dispatch(GET_PRODUCTS, { sku, storeCode })
      .then(products => {
        resolve(products);
      })
      .catch(err => {
        reject(err.message);
      });
  });

export default {
  name: 'ProductDetail',
  components: {
    Carousel,
    Slide
  },
  mixins: [LoadingMixin],
  data() {
    return {
      loading: false,
      storeCode: this.$route.params.storeCode,
      sku: this.$route.params.sku,
      group: this.$route.params.group,
      productSelected: '',
      packName: '',
      eurFormat,
      currency,
      slides: [],
      nextLabel: '&#8250',
      prevLabel: '&#8249',
      especialUnityCapacityValues,
      stringToDate,
      searchedSku: '',
      items: 0
    };
  },
  loading: {
    methods: ['fetchDataProduct'],
    property: 'loading'
  },
  async beforeRouteEnter(to, from, next) {

    try {

      const { group, sku, storeCode } = to.params;

      let storeSelected = null;
      if (storageAvailable('localStorage')) {
        storeSelected = lsStoreSelected();
        if (storeSelected && storeSelected.code === storeCode) {
          await store.dispatch(SET_STORE_SELECTED, storeSelected);
        } else {
          await getStores(storeCode).then(store => {
            storeSelected = store[0].storeInfo;
            lsSetSelectedStore(store[0].storeInfo);
          });
        }
      } else {
        await getStores(storeCode).then(store => {
          storeSelected = store[0].storeInfo;
        });
      }

      // Si no hay una tienda seleccionada enrutar a pagina de error
      if (!storeSelected) {
        return next({ name: 'page-not-found' });
      }

      // Obtener producto en Vue store si existe
      const productsInStore = await store.getters['getDataProducts']('products');

      if (!productsInStore) {
        await getProducts(group ? group : sku, storeCode)
          .then(products => {
            // Recorrer productos y decidir si se enruta a lista de productos o se muestra ficha
            if ((group && sku && storeCode) || (products.items.length === 1))
              next({
                name: 'product-detail',
                params: {
                  sku: products.items[0].sku,
                  storeCode
                }
              });
            else {
              next({
                name: 'Products',
                params: {
                  group: group ? group : sku,
                  storeCode
                }
              });
            }
          })
          .catch(err => {
            if (err && err.includes('404')) {
              next({ name: 'page-not-found' });
            } else {
              next({ name: 'Error' });
            }
          });
      } else {
        next();
      }
    } catch (e) {
      console.log('Exception: ' + e);
      next({ name: 'Error' });
    }
  },
  async mounted() {
    await this.fetchDataProduct();
  },
  methods: {
    searchProductByReference(){
      if (this.searchedSku){

        if (this.searchedSku === this.$route.params.sku){
          this.searchedSku = "";
        } else {
          this.$router.push({name: 'product-detail', params: { sku: this.searchedSku, storeCode: this.storeCode }})
          this.$router.go(0);
        }

      }
    },
    ...mapActions([SET_STORE_SELECTED]),
    async changeStore() {
      this.showStoreData = false;
      await this.SET_STORE_SELECTED('');
      const store = lsStoreSelected;
      if (store) lsRemoveSelectedStore();
      this.$router.push({name: 'Stores', params: { sku: this.sku, changeStore: true, productName: this.productSelected.productName, 
      productReference: this.productSelected.sku, productDate: stringToDate(this.productSelected.lastUpdateDate) }})
    },
    fetchDataProduct() {
      return new Promise((resolve, reject) => {

        if (this.sku && this.storeCode) {

          const productsInStore = store.getters['getDataProducts']('products');
          this.items = productsInStore.items.length;

          if (this.slides.length > 0) this.slides = [];

          if (productsInStore.items && productsInStore.items.length > 0) {

            if (productsInStore.items.length === 1) this.sku = productsInStore.items[0].sku; // Collections with only 1 article

            const index = productsInStore.items.findIndex(product => product.sku === this.sku);
            if (index > -1) {
              this.productSelected = productsInStore.items[index];
              // Process images
              if (this.productSelected.images.length > 0) {
                const images = this.productSelected.images.slice();
                images.forEach(image => {
                  this.slides.push(image);
                });
              }

              this.productSelected.tutorials = [];
              if (this.productSelected.videos.length > 0) {
                this.productSelected.tutorials.push({
                  icon: 'Play48',
                  label: this.$t('ui.button.btnSeeVideo'),
                  url: this.productSelected.videos[0]
                });
              }

              if (this.productSelected.pdfs.length > 0) {
                this.productSelected.tutorials.push({
                  icon: 'Pdf48',
                  label: this.$t('ui.button.btnSeePDF'),
                  url: this.productSelected.pdfs[0]
                });
              }

              if (productsInStore.items.length === 1) {
                this.packName = ''; // If a collection only has one item, pack name will be left empty to display product detail page correctly
              } else {
                this.packName = productsInStore.packName ? productsInStore.packName : '';
              }
               
              resolve();

            }
          }
        } else {
          this.$router.push({ name: 'page-not-found' });
        }
      });
    },
    formatPromotionPrice(product) {
      const { originalPrice, catalogPrice, finalPrice, unitCapacity, capacityQuantity } = product;

      const priceToUse = catalogPrice > finalPrice ? catalogPrice : originalPrice;

      return especialUnityCapacityValues.includes(unitCapacity)
        ? `${this.eurFormat(priceToUse / capacityQuantity)}/${unitCapacity}`
        : this.eurFormat(priceToUse);
    },
    formatCatalogPrice1(product) {
      let finalPriceToRender = '';
      const {
        originalPrice,
        catalogPrice,
        finalPrice,
        unitSale,
        unitCapacity,
        capacityQuantity
      } = product;

      if (especialUnityCapacityValues.includes(unitCapacity)) {
        finalPriceToRender = `${this.eurFormat(catalogPrice / capacityQuantity)}/${unitCapacity}`;
      } else {
        finalPriceToRender = this.eurFormat(catalogPrice);
      }

      return finalPriceToRender;
    },
    formatFinalPrice(product) {
      const { finalPrice, unitCapacity, capacityQuantity } = product;
      return especialUnityCapacityValues.includes(unitCapacity)
        ? `${this.eurFormat(finalPrice / capacityQuantity)}/${unitCapacity}`
        : this.eurFormat(finalPrice);
    },
    formatPriceByUnity(product) {
      const { finalPrice, unitSale, unitCapacity, capacityQuantity, quantityPrice } = product;
      const textByUnitCapacity = textByUnitCapacityFn(product);

      return textByUnitCapacity.unitCapacity.includes(unitCapacity)
        ? textByUnitCapacity.textRender
        : null;
    }
  }
};
</script>
<style lang="scss" scoped>

.VueCarousel-navigation-next {
  transform: translateY(-50%) translateX(50%) !important;
  font-size: 50px !important;
}

.ml-flexy__col--full {
  margin-top: 8px;
}

.mc-button {
  margin-left: 3px;
}

.center {
  text-align: center;
}

.description {
  text-align: justify;
}

</style>
