import { eurFormat } from '@/utils/numbers';

export const textByUnitCapacityFn = product => {
  const { finalPrice, unitSale, unitCapacity, capacityQuantity, quantityPrice } = product;
  const textByUnitCapacity = {
    unitCapacity: especialUnityCapacityValues,
    textRender: `1 ${unitSale} sale a <b>${eurFormat(
      finalPrice
    )}</b></br> 1 ${unitSale} contiene ${capacityQuantity} ${unitCapacity}`,
    textRender2: `1 ${unitCapacity} sale a <b>${eurFormat(
      quantityPrice
    )}</b>, 1 ${unitSale} contiene ${capacityQuantity} ${unitCapacity}`,
    textRenderObj: {
      m2: `1 ${unitSale} sale a <b>${eurFormat(
        finalPrice
      )}</b>, 1 ${unitSale} contiene ${capacityQuantity} ${unitCapacity}`,
      kg: `${unitCapacity} sale a <b>${eurFormat(
        quantityPrice
      )}</b> La unidad contiene ${capacityQuantity} ${unitCapacity}`,
      litro: `El ${unitCapacity} sale a <b>${eurFormat(
        quantityPrice
      )}</b> La unidad contiene ${capacityQuantity} ${unitCapacity}`
    }
  };

  return textByUnitCapacity;
};

export const especialUnityCapacityValues = [
  'rollo',
  'caja',
  'lote',
  'panel',
  'm2',
  'm3',
  'kg',
  'KG',
  'litro',
  'gramo',
  'rollo',
  'tonelda',
  'watios'
];
